<template>
  <div>
    <b-overlay :show="isLoading" spinner-small>
      <b-card>
        <h4>{{ code }}</h4>
        <hr />
        <p v-if="errorMessage" class="text-center text-danger my-2">
          {{ errorMessage }}
        </p>
        <div v-else class="mt-2">
          <app-timeline>
            <app-timeline-item v-for="item in listData" :key="item.id">
              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h6>{{ item.user_id }}</h6>
                <small class="text-muted">{{ gFormatDate(item.created_at, true) }}</small>
              </div>
              <p>ชื่อที่สร้าง {{ item.text }}</p>
              <b-img :src="item.cloudflare_url" height="auto" width="100" class="ct-cursor-zoom" @click="gZoomImage" />
            </app-timeline-item>
          </app-timeline>

          <p v-if="listData.length === 0 && !isLoading" class="text-center m-0 text-muted">
            ยังไม่มีข้อมูลผู้ใช้ Redeem Code นี้
          </p>
        </div>
      </b-card>
    </b-overlay>
    <!-- <pre>{{ listData }}</pre> -->
  </div>
</template>

<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
  },
  data() {
    return {
      isLoading: false,
      listData: [],
      code: '',
      errorMessage: '',
    }
  },
  created() {
    const { redeemCodeId, redeemCode } = this.$route.params
    this.code = redeemCode
    if (redeemCodeId) {
      this.fetchUseByListData(redeemCodeId)
    }
  },
  methods: {
    async fetchUseByListData(redeemCodeId) {
      this.errorMessage = ''
      this.isLoading = true
      const resp = await this.api.getV2(`api/admin/redeeming-code-data/use-bys/${redeemCodeId}`).catch(() => null)
      this.isLoading = false
      if (!resp) {
        this.errorMessage = 'พบข้อผิดพลาดในการดึงข้อมูล โปรดลองรีเฟรชหน้าจออีกครั้ง'
        return
      }

      if (resp.code === 200) {
        this.listData = [...resp.data]
      } else {
        this.errorMessage = 'พบข้อผิดพลาดในการดึงข้อมูล โปรดลองรีเฟรชหน้าจออีกครั้ง'
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
